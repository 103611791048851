/**
 * Never edit this file manually. It is generated as part of the building process
 */
export const AppInfo = {
    name: 'medion-alditalk-client',
    appName: 'medion-alditalk-client',
    version: '2.2.0' ,
    defaultLocale: 'de',
    di18nMode: 'TRANSLATE',
    git: {
        shortHash: 'beadc20',
        hash: 'beadc20ce91617bf7ef2481c8a07ef77b37d8b54',
        subject: 'Merge branch \'next\' into \'master\'',
        sanitizedSubject: 'Merge-branch-next-into-master',
        authoredOn: '1733399809',
        committedOn: '1733399809',
        branch: 'HEAD',
    }
};

